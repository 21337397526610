#sphere-canvas {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    scale: 1;
    z-index: 99999;
}

.text-element {
    font-family: var(--command-prompt-font);
    font-weight: bolder;
    font-size: medium;
    width: 30vw;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 1vw;
    backdrop-filter: blur(10px);
    margin-left: -10vw;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .text-element {
        width: 70vw;
        font-size: medium;
    }
}