#navigation-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: transparent;
    backdrop-filter: blur(20px);
    z-index: 9999;
    overflow-y: scroll;
    padding: 1rem;
    text-align: center;
    scale: 0;
}

#navigation-container img {
    width: 30%;
    margin-top: -10vh;
}

#navigation-container .navigation-description {
    position: sticky;
    top: 40vh;
    left: 2vw;
    font-family: var(--command-prompt-font);
    color: #fff;
    font-weight: 100;
    text-align: left;
    width: 30vw;
}

#navigation-container .close-navigation {
    position: sticky;
    top: 5vh;
    left: 2vw;
    font-family: var(--command-prompt-font);
    color: #fff;
    font-weight: 100;
    text-align: left;
    width: 30vw;
}

#navigation-container .close-navigation h1 {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #navigation-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    #navigation-container .close-navigation {
        text-align: center;
        margin-bottom: 5vh;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }

    #navigation-container .navigation-description {
        position: unset;
        width: 90vw;
        text-align: center;
        font-size: small;
    }

    #navigation-container img {
        margin-top: 5vh;
        width: 80%;
        margin-bottom: 5vh;
    }
}