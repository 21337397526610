#blob-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  filter: blur(100px);
  background-color: #FF6233;
  transform: scale(1.2);
  background-clip: padding-box;
}

.shape-blob {
  position: absolute;
  transform: rotate(-180deg);
  opacity: 1;
  animation: transform 12s ease-in-out infinite both alternate,
             movement 10s ease-in-out infinite both;
}

.shape-blob.one {
  background: #004DFF;
  height: 1200px;
  width: 1000px;
  border-radius: 40% 50% 30% 40%;
  animation-delay: 0s;
}

.shape-blob.two {
  background: #80FF44;
  height: 1200px;
  width: 1089px;
  border-radius: 50% 30% 40% 60%;
  animation-delay: -2s;
}

.shape-blob.three {
  background: #FF6233;
  height: 880px;
  width: 1000px;
  border-radius: 60% 40% 50% 30%;
  animation-delay: -4s;
}

.shape-blob.four {
  background: #004DFF;
  height: 600px;
  width: 900px;
  border-radius: 30% 40% 50% 60%;
  animation-delay: -6s;
}

.shape-blob.five {
  background: #80FF44;
  height: 800px;
  width: 1000px;
  border-radius: 40% 60% 30% 50%;
  animation-delay: -8s;
}

.shape-blob.six {
  background: #FF6233;
  height: 1000px;
  width: 900px;
  border-radius: 60% 30% 50% 40%;
  animation-delay: -10s;
}

.shape-blob.seven {
  background: #004DFF;
  height: 700px;
  width: 800px;
  border-radius: 50% 40% 60% 30%;
  animation-delay: -12s;
}

.shape-blob.eight {
  background: #80FF44;
  height: 900px;
  width: 1200px;
  border-radius: 30% 50% 40% 60%;
  animation-delay: -14s;
}

.shape-blob.nine {
  background: #004DFF;
  height: 100%;
  width: 50%;
  border-radius: 50% 40% 60% 30%;
  animation-delay: -12s;
}

.shape-blob.ten {
  background: #80FF44;
  height: 100%;
  width: 50%;
  border-radius: 30% 50% 40% 60%;
  animation-delay: -14s;
}

.stage-one {
  background-color: #80FF44 !important;
}

.stage-two-transition {
  background-color: #004DFF !important;
}

.stage-two {
  background-color: #004DFF !important;
}

.stage-three-transition {
  background-color: #FF6233 !important;
}

.stage-three {
  background-color: #FF6233 !important;
}

/* Add more shape-blob definitions with different background colors, sizes, and delays */

@keyframes transform {
  0%, 100% {
    border-radius: 33% 67% 70% 30% / 30% 40% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49% / 37% 35% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 26%;
  }
  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 73%;
  }
  80% {
    border-radius: 40% 60% 42% 58% / 51% 51% 49% 59%;
  }
  /* Adjust the other keyframe percentages */
}

@keyframes movement {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: translate(100%, 50%) rotateY(180deg) scale(1);
  }
  /* Adjust the other keyframe percentages and transformations */
}

/* Add media queries for responsiveness if needed */
